import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

const jobSundryName = 'jobSundry';

export const useGetJobSundryId = () => {
    const [query, setQuery] = useSearchParams();
    const [jobSundryId, setJobSundryId] = useState<number>(
        query.get(jobSundryName) ? Number(query.get(jobSundryName)) : null
    );

    useEffect(() => {
        const id = query.get(jobSundryName);

        if (id != null) {
            setJobSundryId(Number(id));
        } else {
            setJobSundryId(null);
        }
    }, [query]);

    const setJobSundry = (id: number) => {
        setQuery({[jobSundryName]: String(id)});
    };

    return {jobSundryId, setJobSundry};
};
