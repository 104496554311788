import {
    BenchtopMaterial,
    FormFactor,
} from 'components/customer/BTM/entity/BenchtopMaterial';
import {FormFactorIdentifier} from 'components/customer/BTM/entity/BenchtopFormFactor';
import {highlightWord} from 'components/customer/Materials/helper';
import {isEqual, sortBy, identity} from 'lodash';

export const mapDisplayName = (material: BenchtopMaterial) => {
    material.displayName = `${material.name} <em>${material.finish.name}</em> - ${material.thickness}mm ${material.brand.name}`;

    if ('substrate' in material) {
        material.displayName += ` ${material.substrate.name}`;
    }

    material.searchName = `${material.name} ${material.brand.name} ${material.finish.name}`;

    return material;
};

export const mapHighlightedWord =
    (keyword: string) => (material: BenchtopMaterial) => {
        material.displayName = highlightWord(material.displayName, keyword);

        return material;
    };

const getFormFactorByIdentifiers = (identifiers: FormFactorIdentifier[]) => {
    let formFactor = FormFactor.NONE;
    const formFactorMap = {
        [FormFactor.NONE]: [FormFactorIdentifier.NONE],
        [FormFactor.DP]: [FormFactorIdentifier.DP],
        [FormFactor.SP]: [FormFactorIdentifier.SP],
        [FormFactor.SP_DP]: [FormFactorIdentifier.DP, FormFactorIdentifier.SP],
        [FormFactor.NONE_SP]: [
            FormFactorIdentifier.NONE,
            FormFactorIdentifier.SP,
        ],
        [FormFactor.NONE_DP]: [
            FormFactorIdentifier.DP,
            FormFactorIdentifier.NONE,
        ],
        [FormFactor.NONE_SP_DP]: [
            FormFactorIdentifier.DP,
            FormFactorIdentifier.NONE,
            FormFactorIdentifier.SP,
        ],
    };

    for (const key in FormFactor) {
        if (!isNaN(Number(key))) {
            const testArray = formFactorMap[key];

            if (isEqual(testArray, identifiers)) {
                formFactor = Number(key) as FormFactor;
                break;
            }
        }
    }

    return formFactor;
};

export const mapFormFactors = (
    material: BenchtopMaterial
): BenchtopMaterial => {
    if (material.formfactors) {
        let allowedFormFactors: number[] = [];

        if (
            material.matrix_available != null &&
            material.matrix_available != ''
        ) {
            allowedFormFactors = material.matrix_available
                .split(',')
                .map(Number);
        }

        const identifiers = material.formfactors
            .filter((formfactor) => {
                return allowedFormFactors.includes(formfactor.id);
            })
            .map((formFactor) => formFactor.identifier);

        material.formFactor = getFormFactorByIdentifiers(
            sortBy(identifiers, identity)
        );
    }

    return material;
};

export const mapMaterialDimension = (material: BenchtopMaterial) => {
    if (material?.change?.actualChangesHappen) {
        if (material?.change?.max_depth != null) {
            material.max_depth = parseInt(material.change.max_depth);
        }

        if (material?.change?.max_length != null) {
            material.max_length = parseInt(material.change.max_length);
        }
    }

    return material;
};

export const mapSingleProfileDepth = (material: BenchtopMaterial) => {
    if ('min_in_SP' in material) {
        material.minSingleProfileDepth = parseInt(String(material.min_in_SP));
    }

    if ('max_in_SP' in material) {
        material.maxSingleProfileDepth = parseInt(String(material.max_in_SP));
    }

    return material;
};

export const mapButtJoin = (material: BenchtopMaterial) => {
    if ('allow_butt_join' in material) {
        material.allowButtJoin = (material.allow_butt_join as boolean) ?? false;
    }

    if ('max_butt_join_piece' in material) {
        material.maxButtJoin = (material.max_butt_join_piece as number) ?? null;
    }

    if (material?.change?.actualChangesHappen) {
        if ('allow_butt_join' in material.change) {
            material.allowButtJoin = material.change.allow_butt_join;
        }

        if ('max_butt_join_piece' in material.change) {
            material.maxButtJoin = parseInt(
                material.change.max_butt_join_piece
            );
        }
    }

    return material;
};
