import React from 'react';
import {
    Outlet,
    Route,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';

import {AuthGuard} from '../AuthGuard';
import {App} from '../App';
import {Config} from 'config';
import {FourZeroFourRaw} from 'shared';
import {FullscreenPreview} from 'components/customer/BTM/Preview/FullscreenPreview';
import {FullscreenTDLDPreview} from 'components/customer/LayoutDesigner/FullscreenTDLDPreview';
import {WaitForUser} from 'WaitForUser';
import {ContextManager} from 'contexts';
import {PageEvents} from 'shared/components/Analytics/PageEvents';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/v2/*" element={<Outlet />}>
            <Route
                path="btm-preview"
                element={
                    <WaitForUser allowRedirect={false}>
                        <PageEvents>
                            <FullscreenPreview />
                        </PageEvents>
                    </WaitForUser>
                }
            />
            <Route
                path="tdld-preview"
                element={
                    <WaitForUser allowRedirect={false}>
                        <PageEvents>
                            <ContextManager>
                                <FullscreenTDLDPreview />
                            </ContextManager>
                        </PageEvents>
                    </WaitForUser>
                }
            />
            <Route
                path="*"
                element={
                    <WaitForUser>
                        <AuthGuard>
                            <PageEvents>
                                <App Config={Config} />
                            </PageEvents>
                        </AuthGuard>
                    </WaitForUser>
                }
            />
            <Route
                path="*"
                element={
                    <PageEvents>
                        <FourZeroFourRaw />
                    </PageEvents>
                }
            />
        </Route>
    )
);
